import React, { lazy, Suspense, useEffect, useState } from 'react'
// import { Redirect } from 'react-router-dom'
import { AuthRoute, Switch, Route, NotFound } from '@fs/zion-router'
import { targetEnv } from '@fs/zion-config'
import ErrorBoundary from '@fs/zion-error-boundary'
import getPermission from '@fs/zion-permissions'
import zionDebug from '@fs/zion-debug'
import { BrightspotPage, BrightspotRouting } from '@fs/zion-cms'
import { ErrorPage } from '@fs/zion-error-page'
import { useTranslation } from 'react-i18next'
import HomePageSkeleton from './components/HomePageSkeleton'
import StatePage from './components/StatePage'
import DebugPage from './components/DebugPage'
import SpeedTest from './components/SpeedTest'
import RootsTechBannerPage from './components/RootsTechBannerPage'

const debug = zionDebug('zion-cms')

const EmbeddedCMSContent = lazy(() => import('./components/EmbeddedCMSContent'))

function SEOSubsite() {
  const [permissionOK, setPermissionOK] = useState(false)
  const [t] = useTranslation()

  useEffect(() => {
    async function checkPermission() {
      const auth = await getPermission('ThemisPrmLdsMember', 'CrxCtxPublicAccess')
      if (auth.authorized) {
        setPermissionOK(true)
      }
    }

    checkPermission()
  }, [])

  if (!permissionOK) {
    return (
      <ErrorPage
        image="unauthorized"
        heading={t('translation:error.unauthorized.heading', 'Access Denied')}
        body={t(
          'translation:error.unauthorized.body',
          'You do not have the necessary permissions to access this page. Please contact your administrator if you believe this is an error.'
        )}
      />
    )
  }
  return <BrightspotRouting site="fhsites-beta" />
}

function App({ site }) {
  const location = window?.location

  debug(`App.js site=${site}, location:${location}`)
  const rootMatchPath = '/([a-z]{2}|zh-hans|ceb)'
  // const localePart = location.pathname.match(rootMatchPath)[1]

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<HomePageSkeleton />}>
          <Switch>
            {/* <Redirect from={`${rootMatchPath}/dna`} to={`/${localePart}/dna-testing`} /> */}
            <Route path={`${rootMatchPath}/zion-cms/debug`} render={() => <DebugPage />} />
            <Route exact path={`${rootMatchPath}/zion-cms/speedtest-react`} render={() => <SpeedTest />} />
            {targetEnv !== 'prod' && (
              <>
                {site === 'zion-cms-beta' && (
                  <Switch>
                    <Route
                      path={`${rootMatchPath}/zion-cms/demo/stand-alone-page`}
                      render={() => <BrightspotPage url="https://beta.familysearch.org/zion-cms/demo-internal/page" />}
                    />
                    <Route
                      path={`${rootMatchPath}/zion-cms/demo/embedded-cms-content`}
                      render={() => <EmbeddedCMSContent />}
                    />
                    <Route path={`${rootMatchPath}/zion-cms/state`} render={() => <StatePage />} />
                    <Route exact path={`${rootMatchPath}/zion-cms/rootstech`} render={() => <RootsTechBannerPage />} />
                    <Route path={`${rootMatchPath}/zion-cms`} render={() => <BrightspotRouting site={site} />} />
                  </Switch>
                )}

                <Switch>
                  <Route
                    path={`${rootMatchPath}/zion-cms-qa/brightspot-page-test`}
                    render={() => <BrightspotPage url="https://beta.familysearch.org/zion-cms-qa/" />}
                  />
                  <Route path={`${rootMatchPath}/zion-cms-qa`} render={() => <BrightspotRouting site={site} />} />
                </Switch>
                <Route path={`${rootMatchPath}/blog`} render={() => <BrightspotRouting site={site} />} />
                <Route path={`${rootMatchPath}/centers`} render={() => <BrightspotRouting site={site} />} />
                <Route path={`${rootMatchPath}/consultant`} render={() => <BrightspotRouting site={site} />} />
                <Route path={`${rootMatchPath}/dna-testing`} render={() => <BrightspotRouting site={site} />} />
                <Route
                  path={`${rootMatchPath}/innovate/solutions-gallery`}
                  render={() => <BrightspotRouting site={site} />}
                />
                <Route
                  path={`${rootMatchPath}/innovate/solutionsgallery`}
                  render={() => <BrightspotRouting site={site} />}
                />
                <Switch>
                  <AuthRoute path={`${rootMatchPath}/fhsites/seo`} component={SEOSubsite} />
                  <Route path={`${rootMatchPath}/fhsites`} render={() => <BrightspotRouting site={site} />} />
                </Switch>
                <Route path={`${rootMatchPath}/library`} render={() => <BrightspotRouting site={site} />} />
                <Route
                  path={`${rootMatchPath}/family-history-library`}
                  render={() => <BrightspotRouting site={site} />}
                />
                <Route path={`${rootMatchPath}/mobile-apps`} render={() => <BrightspotRouting site={site} />} />
                <Route path={`${rootMatchPath}/newsroom`} render={() => <BrightspotRouting site={site} />} />
              </>
            )}
            {targetEnv === 'prod' && <>{site && <BrightspotRouting site={site} />}</>}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

export default App
