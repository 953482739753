import React from 'react'
import { css } from '@emotion/core'
import { gql, useQuery } from '@apollo/client'
import { useLocation } from '@fs/zion-router'
import { Paragraph, colors, Grid, H1, Overline, Subheading } from '@fs/zion-ui'
import { BrightspotSharedModulesContent } from '@fs/zion-cms'
import Map, { Marker } from '@fs/zion-map'
import './leaflet.css'

const QUERY = gql`
  query MyQuery($path: String) @api(contextKey: "apiName") {
    StateDemo(path: $path) {
      bannerColor
      code
      location {
        x
        y
      }
      name
      stateBird
      stateFlag {
        publicUrl
      }
      stateFlower
      stateTree
      sharedReference {
        _id
      }
    }
  }
`

export default function StatePage() {
  const { pathname } = useLocation()
  const pathParts = pathname.split('/')
  const stateCode = pathParts[pathParts.length - 1]
  const { loading, error, data } = useQuery(QUERY, {
    variables: { path: `/${stateCode}` },
    context: { apiName: 'zion-cms-beta:custom' },
  })

  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error! ${JSON.stringify(error)}</div>
  }
  if (!data) {
    return <div>No data returned</div>
  }

  let bgColor = colors.blue02
  if (data.StateDemo.bannerColor) {
    bgColor = colors[`${data.StateDemo.bannerColor}05`]
  }

  return (
    <>
      <div
        css={css`
          text-align: center;
          background: ${bgColor};
          padding: 10px;
        `}
      >
        <H1 size="H3" background={colors.green}>
          <Overline>{`${data.StateDemo.code}`}</Overline>
          {data.StateDemo.name}
          <Subheading>State Details</Subheading>
        </H1>
        <hr />
        <Grid>
          <StateFlag columns={3} name={data.StateDemo.name} url={data.StateDemo?.stateFlag?.publicUrl} />
          <StateIcon columns={2} title="State Bird" value={data.StateDemo?.stateBird || 'Unknown'} />
          <StateIcon columns={2} title="State Flower" value={data.StateDemo?.stateFlower || 'Unknown'} />
          <StateIcon columns={2} title="State Tree" value={data.StateDemo?.stateTree || 'Unknown'} />
          <StateFlag columns={3} name={data.StateDemo.name} url={data.StateDemo?.stateFlag?.publicUrl} />
        </Grid>
      </div>
      {!data.StateDemo?.location && <Paragraph size="sm">No Location Specified</Paragraph>}
      {data.StateDemo?.location && (
        <div
          css={css`
            margin-top: 20px;
            height: 400px;
            width: 100%;
          `}
        >
          <Map
            bing
            initialLatitude={data.StateDemo?.location?.x}
            initialLongitude={data.StateDemo?.location?.y}
            initialZoom={5}
          >
            <Marker
              latitude={data.StateDemo?.location?.x}
              longitude={data.StateDemo?.location?.y}
              label={data.StateDemo?.name}
            />
          </Map>
        </div>
      )}
      {/* eslint-disable-next-line no-underscore-dangle */}
      {data.StateDemo?.sharedReference?._id && (
        <div
          css={css`
            width: 90%;
            padding: 10px;
            margin-top: 30px;
            margin-inline-start: 20px;
          `}
        >
          {/* eslint-disable-next-line no-underscore-dangle */}
          <BrightspotSharedModulesContent url={data.StateDemo?.sharedReference?._id} site="zion-cms-beta" />
        </div>
      )}
      {/* eslint-disable-next-line no-underscore-dangle */}
      {!data.StateDemo?.sharedReference?._id && (
        <h4
          css={css`
            text-align: center;
            padding: 10px;
          `}
        >
          No Shared Reference
        </h4>
      )}
    </>
  )
}

function StateFlag({ name, url }) {
  if (!url) {
    return <Paragraph size="sm">No Flag</Paragraph>
  }
  return (
    <img
      css={css`
        width: 300px;
        margin: auto;
      `}
      alt={`State flag for ${name}`}
      src={url}
    />
  )
}

function StateIcon({ title, value }) {
  return (
    <span
      css={css`
        border: 1px solid black;
        height: 100%;
        background: white;
      `}
    >
      <H1 size="H5">
        {title}
        <Subheading>{value}</Subheading>
      </H1>
    </span>
  )
}
